const content = {
  aboutBubbasBeds: `Bubba's Beds for Shelter Friends is a 501(c)(3) nonprofit 
    that provides orthopedic, chew-proof and easy-to-clean beds 
    for shelter dogs and cats. We are a 100% volunteer group 
    dedicated to giving comfort to shelter animals and also promoting 
    a cruelty-free, compassionate lifestyle toward all animals.`,
  whoIsBubba: `Bubba was a rescue pup adopted in 2002 after a rough start to life. 
    Once he was shown compassion and comfort, his whole personality changed! 
    He lived the next 12 years as a happy and adventurous friend to all he met. 
    Bubba's Beds for Shelter Friends was formed in his honor as a testament 
    to how a little love can make a world of difference to a shelter animal.`,
  whyKurandaBeds: `We believe, and thousands of shelter animals agree, that BEDS MATTER! 
    Just imagine living and sleeping on a cold, wet, concrete floor. 
    Traditional dog beds get chewed up, pose a choking risk, 
    and are a nightmare for shelter staff to clean. We've found after 
    much research that Kuranda beds are the answer - virtually 
    indestructible, raised off the ground to provide relief and ease pressure 
    points, and easy for shelter staff to clean.`
};

export default content;
