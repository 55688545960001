import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './Draw.css';

class Draw extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-162159065-1');
  }

  trackEvent = (drawing) => {
    ReactGA.event({
      category: 'Drawings',
      action: 'Download',
      label: drawing
    });
  }

  render() {
    const drawings = [
      `Jack the Pirate.png`,
      `Hank and his Hummingbirds.png`,
      `Godiva on a Gondola.png`,
      `Amita's Magic Bed Ride.png`,
      'Princess at the Beach.jpg',
    ];
    return (
      <div>
        <h1>Download pages from our free coloring book!</h1>
        <div className="drawings">
          {drawings.map(drawing => (
            <div className="drawing-container" key={drawing}>
              <h2>"{drawing.split('.')[0]}"</h2>
              <div className="drawing">
                <a
                  download={drawing}
                  href={`/coloring-book/${drawing}`}
                  onClick={ () => this.trackEvent(drawing) }
                >
                  <img src={`/coloring-book/${drawing}`} alt={drawing} className="drawing-page" />
                  <img src={require('./images/download-icon.png')} alt='Download' className="download-icon" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Draw;
