import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import './Home.css';
import ActionButton from './ActionButton';
import config from './config';

class Home extends Component {
  render() {
    const imageUrl = num => `${config.cloudfrontUrl}home${num}${isMobile ? '-small' : ''}.jpg`;
    const isMobile = window.innerWidth < 480;
    return (
    <Carousel>
      <Carousel.Item>
        <img
          className="carousel-img"
          src={imageUrl('1')}
          alt="Learn More"
        />
        <Carousel.Caption>
          <h2 className="carousel-text">Improving quality of life for shelter animals, one bed at a time.</h2>
          <a href="/#about">
            <ActionButton text="LEARN MORE" buttonClass={isMobile ? 'button-x-small' : ''} />
          </a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="carousel-img"
          src={imageUrl('2')}
          alt="Like us on Facebook"
        />
        <Carousel.Caption>
          <h2 className="carousel-text">Raising money through community-oriented fundraisers.</h2>
          <a href="https://www.facebook.com/bubbasbedsforshelterfriends/" target="_blank" rel="noopener noreferrer">
            <ActionButton text="LIKE US ON FACEBOOK" buttonClass={isMobile ? 'button-x-small' : ''} />
          </a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="carousel-img"
          src={imageUrl('3')}
          alt="Donate"
        />
        <Carousel.Caption>
          <h2 className="carousel-text">Assembling and delivering beds all over the country.</h2>
          <a href="/#donate">
            <ActionButton text="DONATE" buttonClass={isMobile ? 'button-x-small' : ''} />
          </a>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

    );
  }
}

export default Home;
