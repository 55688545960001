import React from 'react';
import ActionButton from './ActionButton';
import './Donate.css';

const giveLivelyUrl = 'https://secure.givelively.org/donate/bubbas-beds-for-shelter-friends';

export default () => (
  <div className="donate-section-container">
    <div className="donate-intro">
      <h1>We believe every shelter animal deserves a warm bed.</h1>
      <h2><b>You can help us make that dream a reality.</b></h2>
      <p className="donate-description">100% of donations go directly to providing Kuranda dog and cat beds. Since 2016, Bubba’s Beds has provided beds across the USA, Rwanda, Puerto Rico, and St. Croix, as well as following natural disasters.</p>
    </div>
    <div className="donate-option-container">
      <a href={giveLivelyUrl} target="_blank" rel="noopener noreferrer">
        <ActionButton text="DONATE" />
      </a>
    </div>
    <div className="donate-option-container">
      <p>
        Donations may also be made via Venmo (<a href="https://venmo.com/u/bubbasbeds" target="_blank" rel="noopener noreferrer">@bubbasbeds</a>) or PayPal (<a href="https://www.paypal.com/donate?hosted_button_id=H62XFXMPTN3ME" target="_blank" rel="noopener noreferrer">bubbasbedsforshelterfriends@gmail.com</a>).
      </p>
    </div>
    <h2><b>Thank you for your support!</b></h2>
  </div>
);
