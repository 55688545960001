import React, { Component } from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import './Header.css';

class Header extends Component {
  render() {
    return (
      <div>
        <Navbar collapseOnSelect fixedTop id="header">
          <Navbar.Header>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavItem
                eventKey={2}
                href="/#about"
                className={this.props.activeSection === "about" ? "active" : ""}
              >
                ABOUT
              </NavItem>
              <NavItem
                eventKey={3}
                href="/#donate"
                className={this.props.activeSection === "donate" ? "active" : ""}
              >
                DONATE
              </NavItem>
              <NavItem
                eventKey={4}
                href="/#draw"
                className={this.props.activeSection === "draw" ? "active" : ""}
              >
                DRAW
              </NavItem>
              <NavItem
                eventKey={5}
                href="/#gallery"
                className={this.props.activeSection === "gallery" ? "active" : ""}
              >
                GALLERY
              </NavItem>
              <NavItem
                eventKey={6}
                href="https://bubbasbeds.bigcartel.com/products"
                target="_blank"
                rel="noopener noreferrer"
              >
                SHOP
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="social-icons">
          <a className="social-icon first-social-icon" href="https://www.facebook.com/bubbasbedsforshelterfriends/" target="_blank" rel="noopener noreferrer">
            <img src={require("./social-icons/facebook.svg")} width="24px" alt="Facebook" />
          </a>
          <a className="social-icon" href="https://www.instagram.com/bubbasbeds/" target="_blank" rel="noopener noreferrer">
            <img src={require("./social-icons/instagram.svg")} width="24px" alt="Instagram" />
          </a>
          <a className="social-icon" href="mailto:bubbasbedsforshelterfriends@gmail.com">
            <img src={require("./social-icons/email.svg")} width="24px" alt="Email" />
          </a>
        </div>
      </div>
    );
  }
}

export default Header;
