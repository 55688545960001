import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './ActionButton.css';

class ActionButton extends Component {
  render() {
    return (
      <Button className={"button "+this.props.buttonClass}>
        {this.props.text}
      </Button>
    );
  }
}

export default ActionButton;
