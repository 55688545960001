import React, { Component } from 'react';
import { Fade } from 'react-reveal';
import Masonry from 'react-masonry-component';
import './Gallery.css';
import config from './config';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: []
    };
    const fetch = require('isomorphic-fetch');
    const Dropbox = require('dropbox').Dropbox;
    this.dbx = new Dropbox({
      accessToken: config.dropboxAccessToken,
      fetch: fetch
    });
  }

  componentWillMount() {
    this.dbx.filesListFolder({path: ''})
      .then(function(response) {
        let photos = [];
        response.entries.forEach((file) => {
          this.dbx.filesGetThumbnail({path: file.path_display, size: 'w1024h768'})
            .then(function(response) {
              photos.push(window.URL.createObjectURL(response.fileBlob));
              this.setState({
                photos
              });
            }.bind(this));
        });
      }.bind(this))
      .catch(function(error) {
        console.log(error);
      });
  }

  render() {
    const photos = this.state.photos.map(function(photo){
      return (
        <Fade bottom key={photo}>
          <img
            src={photo}
            alt="Bubba's Beds"
            width="300"
            className="gallery-photo"
          />
        </Fade>
      );
    });

    return (
      <div className="photo-grid">
        <Masonry options={{'fitWidth': true}}>
          {photos}
        </Masonry>
      </div>
    );
  }
}

export default Gallery;
