import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import Waypoint from 'react-waypoint';
import './App.css';
import About from './About';
import Donate from './Donate';
import Draw from './Draw';
import Footer from './Footer';
import Gallery from './Gallery';
import Header from './Header';
import Home from './Home';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: "home"
    };
  }

  render() {
    return (
      <div>
        <Header {...this.state} />
        <ScrollableAnchor id="home">
          <Waypoint onEnter={(data => this.setState({ activeSection: "home" }))} topOffset="50%">
            <div className="home section">
              <Home />
            </div>
          </Waypoint>
        </ScrollableAnchor>
        <ScrollableAnchor id="about">
          <Waypoint onEnter={(data => this.setState({ activeSection: "about" }))} topOffset="50%">
            <div className="about section">
              <About />
            </div>
          </Waypoint>
        </ScrollableAnchor>
        <ScrollableAnchor id="donate">
          <Waypoint onEnter={(data => this.setState({ activeSection: "donate" }))} topOffset="50%">
            <div className="donate section">
              <Donate />
            </div>
          </Waypoint>
        </ScrollableAnchor>
        <ScrollableAnchor id="draw">
          <Waypoint onEnter={(data => this.setState({ activeSection: "draw" }))} topOffset="50%">
            <div className="draw section">
              <Draw />
            </div>
          </Waypoint>
        </ScrollableAnchor>
        <ScrollableAnchor id="gallery">
          <Waypoint onEnter={(data => this.setState({ activeSection: "gallery" }))} topOffset="50%">
            <div className="gallery section">
              <Gallery />
            </div>
          </Waypoint>
        </ScrollableAnchor>
        <Footer />
      </div>
    );
  }
}

export default App;
