import React, { Component } from 'react';
import { Fade } from 'react-reveal';
import './About.css';
import content from './content';

class About extends Component {
  render() {
    const isMobile = window.innerWidth < 768;
    return (
      <div className="about-main-container">
        <Fade bottom>
          <div className="about-container2">
            <div className="about-container1">
              <div className="about-col1 about-text">
                <h1>About Us</h1>
                <p>{content.aboutBubbasBeds}</p>
              </div>
              <div className="about-col2">
                <img
                  src={require("./images/people.jpg")}
                  className="about-image"
                  height="300"
                  alt="Bubba's Beds"
                />
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="about-container2">
            <div className="about-container1">
              <div className={isMobile ? "hidden about-col1" : "about-col1"}>
                <img
                  src={require("./images/bubba.png")}
                  className="bubba-image about-image"
                  height="300"
                  alt="Bubba"
                />
              </div>
              <div className="about-col2 about-text">
                <h1>Who is Bubba?</h1>
                <p>{content.whoIsBubba}</p>
              </div>
              <div className={isMobile ? "about-col1" : "hidden about-col1"}>
                <img
                  src={require("./images/bubba.png")}
                  className="bubba-image about-image"
                  height="300"
                  alt="Bubba"
                />
              </div>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="about-container2">
            <div className="about-container1">
              <div className="about-col1 about-text">
                <h1>Why Kuranda beds?</h1>
                <p>{content.whyKurandaBeds}</p>
              </div>
              <div className="about-col2">
                <img
                  src={require("./images/kuranda.png")}
                  alt="Kuranda"
                  className="kuranda-image"
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

export default About;
