import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <p className={"copyright " + this.props.className}>
        © {(new Date()).getFullYear()} - <a
          href="https://www.websitesbyhannah.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Websites by Hannah
        </a>
      </p>
    );
  }
}

export default Footer;
